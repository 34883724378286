<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div v-if="show" class="dynamic-form" :class="formClass">
    <h3 v-if="title" class="dynamic-sub-title mb-6" :class="titleClass">
      {{ title }}
    </h3>
    <p v-if="timeOut" class="time-end-text text-2 text-center">
      {{ timeout_text }}
    </p>
    <v-form v-else ref="form" v-model="valid">
      <v-row class="py-3">
        <template v-for="item in items">
          <v-col :key="item.id" v-bind="col(item)" class="py-0">
            <p class="mb-2">
              <span v-if="item.is_required" class="red--text">* </span
              >{{ item.name }}
            </p>
            <Input
              v-if="item.type === 'input'"
              :item="item"
              v-model="formData[item.id]"
            />
            <Selection
              v-if="item.type === 'select'"
              :item="item"
              v-model="formData[item.id]"
            />
            <Textarea
              v-if="item.type === 'text'"
              :item="item"
              v-model="formData[item.id]"
            />
          </v-col>
        </template>
      </v-row>
    </v-form>
    <div v-if="!timeOut" class="text-center my-10">
      <SubmitBtn @click="submit" :fileItem="fileItem">
        {{ submit_text }}
      </SubmitBtn>
    </div>
  </div>
</template>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  components: {
    Input: () => import("./item/input.vue"),
    Selection: () => import("./item/selection.vue"),
    Textarea: () => import("./item/textarea.vue"),
    SubmitBtn: () => import("./submitBtn.vue"),
  },
  props: {
    data: Object,
    formClass: {
      type: String,
      default: "",
    },
    titleClass: {
      type: String,
      default: "",
    },
    title: String,
    grid: {
      type: Object,
      default: () => null,
    },
    form_id: {
      type: String,
    },
    itemFullWidth: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: false,
    formData: null,
  }),
  computed: {
    form() {
      return this.data;
    },
    show() {
      if (!this.form) return false;
      return true;
    },
    items() {
      if (!this.form) return [];
      return this.form.items.sort((a, b) => a.sort - b.sort);
    },
    meta() {
      if (!this.form) return null;
      return this.form.meta;
    },
    timeOut() {
      if (!this.form) return true;
      if (this.form.show == 0) return true;
      if (!this.form.end_at) return true;
      const date = this.$helper.moment(this.form.end_at),
        now = this.$helper.moment();

      return date.isBefore(now);
    },
    timeout_text() {
      if (!this.meta) return "";
      return this.meta.timeout_text;
    },
    submit_text() {
      if (!this.meta) return "";
      return this.meta.submit_text;
    },
    fileItem() {
      if (!this.form) return null;
      if (!this.form.file) return null;
      return this.form.file[0];
    },
    params() {
      let array = [];
      for (let key in this.formData) {
        const value = this.formData[key];
        const targetColumn = this.items.find((item) => item.id === key);
        if (value) {
          if (targetColumn.type === "select") {
            if (Array.isArray(value)) {
              let selection = value.reduce((acc, item) => {
                acc.push(item.id === "other" ? item.value : item.name);
                return acc;
              }, []);
              array.push(selection.join(";"));
            } else {
              array.push(value.id === "other" ? value.value : value.name);
            }
          } else {
            array.push(value);
          }
        } else {
          array.push("");
        }
      }
      return {
        data: array,
      };
    },
    isPreview() {
      return this.$route.meta.preview;
    },
    isSecret() {
      return this.$route.query.secret;
    },
  },
  created() {
    this.setFormData();
  },
  methods: {
    async submit(cb) {
      if (this.isPreview) return;

      this.validate();
      if (!this.valid) {
        this.$snotify.error(this.$t("base.validation.fail"));
        return;
      }
      this.$store.dispatch("base/loading");
      try {
        await this.submitApi();
        this.$snotify.success(this.$t("form.submit.success"));
        await cb();
        this.reset(); // vuetify本身機制不會觸發validate
      } catch (err) {
        console.error(err);
        this.$snotify.error(this.$t("form.submit.error"));
      } finally {
        this.$store.dispatch("base/loading", false);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    setFormData() {
      this.formData = this.items.reduce((acc, item) => {
        acc[item.id] = "";
        return acc;
      }, {});
    },
    isMultiple(item) {
      return !!item.meta.select_multiple;
    },
    isRequired(item) {
      return !!item.is_required;
    },
    col(item) {
      if (this.itemFullWidth)
        return {
          cols: 12,
        };
      if (item.type === "text") return { cols: 12 };
      if (item.type === "select") return { cols: 12 };
      return {
        cols: 12,
        xl: 6,
        lg: 6,
        md: 6,
        sm: 12,
        xs: 12,
        ...this.grid,
      };
    },
    submitApi() {
      return this.$api.page.public.submit(this.form_id, this.params);
    },
    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>