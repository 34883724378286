<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <div>
    <svg
      id="ecvWaveT"
      class="ecvWaveT"
      data-name="ecvWaveT"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1920 30"
    >
      <path
        id="ecvWaveTPath"
        data-name="ecvWaveTPath"
        class="cls-1"
        d="M0,14.51S224.94,30,480.52,30,1194.21,0,1333.24,0,1756,2.05,1920,15V0H0Z"
      />
    </svg>
    <!-- ---------------------------- -->
    <svg
      id="ecvWaveTMobile"
      class="ecvWaveTMobile"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 375 20"
    >
      <path
        id="ecvWaveTMobilePath"
        class="cls-1"
        d="M0,12.26c31.05,5,62.42,7.59,93.85,7.74,49.92,0,153.85-15,181-15s68.12,1.02,100.15,7.5V0H0V12.26Z"
      />
    </svg>
  </div>
</template>

<style scoped lang="sass">
.ecvWaveT
  display: block
  width: 100vw
  @media screen and (max-width:900px)
    display: none
.ecvWaveTMobile
  display: none
  @media screen and (max-width:900px)
    display: block
    width: 100vw
.cls-1
  fill: #ffffff
</style>

<script>
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
</script>
